<template>
  <v-container data-app id="dashboard-view" fluid tag="section">
    <v-layout row wrap>
      <v-flex lg-5>
        <button class="botao-bonito" v-show="showButtonFaturas" @click="mostrarFaturas">Mostrar Faturas</button>
        <BaseWidget title="Filtro">
          <v-layout row wrap>
            <v-flex xs12 md9>
              <v-label style="margin-left:0px"
                ><b>Período:</b></v-label
              >
              <div style="display: flex; align-items: center">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Data Inicial"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <p style="margin: 2%">à</p>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted2"
                      label="Data Final"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date2 = parseDate(dateFormatted2)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                
                <v-btn dark rounded="" style="background-color:#757575;margin-left: 10px;margin-right:20px" @click="buscarTotalizadores()">
                <v-icon left>
                  mdi-filter
                </v-icon>
                <b>Filtrar</b>
              </v-btn>
                
              </div> </v-flex
          >
        <div style="display: flex;align-items: center;">
                <p style="margin-right:10px;font-size:16px">MDF-e</p>
                <label class="switch">
                  <input type="checkbox" v-model="isCTE" >
                  <span class="slider round"></span>
                </label>
                <p style="margin-left:10px;font-size:16px">CT-e</p>
        </div>
        </v-layout>
        </BaseWidget>
      </v-flex>
    </v-layout>
    <v-row v-show="this.isCTE">
      <v-col
        v-for="({ actionIcon, actionText, ...attrs }, i) in statsCTE"
        :key="i"
        cols="12"
        md="4"
        lg="4"
      >
        <MaterialStatsCard v-bind="attrs">
          <template #actions>
            <v-icon class="mr-2" small v-text="actionIcon" />
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </MaterialStatsCard>
      </v-col>

      <v-col cols="12" md="4" lg="4" sm="10" >
        <MaterialCard color="#4CAF50" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                CT-e autorizadas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaCTE.A" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="10">
        <MaterialCard color="#FB8C00" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                CT-e não autorizadas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaCTE.NA" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="10">
        <MaterialCard color="#FF5252" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                CT-e canceladas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaCTE.C" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
    </v-row>
    <v-row v-show="!this.isCTE">
      <v-col
        v-for="({ actionIcon, actionText, ...attrs }, i) in statsMDFE"
        :key="i"
        cols="12"
        md="4"
        lg="4"
      >
        <MaterialStatsCard v-bind="attrs">
          <template #actions>
            <v-icon class="mr-2" small v-text="actionIcon" />
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </MaterialStatsCard>
      </v-col>

      <v-col cols="12" md="4" lg="4" sm="10" >
        <MaterialCard color="#4CAF50" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                MDF-e autorizadas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaMDFE.A" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="10">
        <MaterialCard color="#FB8C00" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                MDF-e não autorizadas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaMDFE.NA" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="10">
        <MaterialCard color="#FF5252" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h6 font-weight-bold">
                MDF-e canceladas por data
              </div>
            </div>
          </template>
          <v-card-text>
            <v-skeleton-loader :loading="loading" type="table-tbody" height="150" >
            <v-data-table :headers="headers" :items="listaMDFE.C" />
            </v-skeleton-loader>
          </v-card-text>
        </MaterialCard>
      </v-col>
    </v-row>
    <DialogFaturasPendentes ref="dFat" v-show="showModal" @close-modal="showModal=false" @semFaturas="esconderBotaoFaturas" @show-modal="showModal=true"/>
  </v-container>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import MaterialCard from "../components/template/MaterialCard";
import MaterialStatsCard from "../components/template/MaterialStatsCard";
import BaseWidget from "../components/BaseWidget";
import Dashboard from "../service/dashboard";
import DialogFaturasPendentes from '../components/FaturasPendentes'
export default {
  name: "Home",
  components: { MaterialCard, MaterialStatsCard, BaseWidget, DialogFaturasPendentes },
  data() {
    return {
      headers: [
        {
          sortable: false,
          text: "Data",
          value: "data",
        },
        {
          sortable: false,
          text: "Quantidade",
          value: "quantidade",
          align: "center",
        },
        {
          sortable: false,
          text: "Valor",
          value: "valor",
          align: "center",
        },
      ],

      statsCTE: [
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#4CAF50",
          icon: "mdi-check-bold",
          title: "CT-e Autorizadas",
          value: "0",
          id: 2,
        },
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#FB8C00",
          icon: "mdi-cancel",
          title: "CT-e Não Autorizadas",
          value: "0",
          id: 3,
        },
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#FF5252",
          icon: "mdi-close-thick",
          title: "CT-e Canceladas",
          value: "0",
          id: 5,
        }
      ],
      statsMDFE: [
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#4CAF50",
          icon: "mdi-check-bold",
          title: "MDF-e Autorizadas",
          value: "0",
          id: 2,
        },
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#FB8C00",
          icon: "mdi-cancel",
          title: "MDF-e Não Autorizadas",
          value: "0",
          id: 3,
        },
        {
          actionIcon: "mdi-cash-multiple",
          actionText: "-",
          color: "#FF5252",
          icon: "mdi-close-thick",
          title: "MDF-e Canceladas",
          value: "0",
          id: 5,
        }
      ],
      tabs: 0,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted2: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      menu2: false,
      listaNotas: {
        CTE: [],
        MDFE: [],
      },
      listaCTE:{
        A:[],
        NA:[],
        C:[]
      },
      listaMDFE:{
        A:[],
        NA:[],
        C:[]
      },
      listaVisivel:{},
      visible:false,
      isCTE:true,
      loading:false,
      showModal:false,
      showButtonFaturas: true
    };
  },
  mounted() {
    this.buscarTotalizadores();
    // this.buscarTotalizadoresPorData();
    // this.buscarTotalizadoresDezSegundos();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),
    buscarTotalizadoresDezSegundos() {
      this.listaVisivel = {}
      this.listaNotas = {}
      // setInterval(() => {
      //   let params = {
      //     dtInicio: this.date,
      //     dtFim: this.date2,
      //   };
      //   Dashboard.buscarTotalizadores(params).then((response) => {
      //     this.listaNotas = response.data
      //     Dashboard.buscarCTE(params).then((responseCTE) => {
      //       this.listaCTE = responseCTE.data
      //       Dashboard.buscarMDFE(params).then((responseMDFE) => {
      //           this.listaMDFE = responseMDFE.data
      //           this.exibirValores()
      //       });
      //     });
      //   });
      //   // this.buscarTotalizadoresPorData();
      // }, 20000);
    },
    buscarTotalizadores() {
      this.setLoading(true,"Carregando dados");
      this.listaVisivel = {}
      this.listaNotas = {}
      let params = {
          dtInicio: this.date,
          dtFim: this.date2,
        };
        this.loading = true
      Dashboard.buscarTotalizadores(params).then((response) => {
          this.listaNotas = response.data
          this.exibirValores()
          this.setLoading(false);
          Dashboard.buscarCTE(params).then((responseCTE) => {
            this.listaCTE = responseCTE.data
            Dashboard.buscarMDFE(params).then((responseMDFE) => {
                this.listaMDFE = responseMDFE.data
                this.exibirValores()
                this.loading = false
            });
          });
        }).catch(()=>{
           window.location.reload()
        });
    },

    exibirValores(){
          this.limparStats();
          this.listaNotas.CTE.forEach((itemResponse) => {
            this.statsCTE.forEach((item) => {
              if (item.id == itemResponse.status) {
                  item.value = itemResponse.quantidade + "";
                  item.actionText = itemResponse.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" });
              }
            })
          }) 
          this.listaNotas.MDFE.forEach((itemResponse) => {
            this.statsMDFE.forEach((item) => {
              if (item.id == itemResponse.status) {
                  item.value = itemResponse.quantidade + "";
                  item.actionText = itemResponse.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" });
              }
            })
          })  
        this.listaCTE.A.forEach(a=>{
          a.data = this.formatDate(a.data)
          a.valor = a.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
        this.listaCTE.NA.forEach(na=>{
          na.data = this.formatDate(na.data)
          na.valor = na.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
        this.listaCTE.C.forEach(c=>{
          c.data = this.formatDate(c.data)
          c.valor = c.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
        this.listaMDFE.A.forEach(a=>{
          a.data = this.formatDate(a.data)
          a.valor = a.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
        this.listaMDFE.NA.forEach(na=>{
          na.data = this.formatDate(na.data)
          na.valor = na.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
        this.listaMDFE.C.forEach(c=>{
          c.data = this.formatDate(c.data)
          c.valor = c.valor.toLocaleString("pt-BR",{ style: "currency", currency: "BRL" })
        })
    },

    limparStats(){
        this.statsCTE.forEach(item=>{
          item.value = "-";
          item.actionText = "-";
        })
        this.statsMDFE.forEach(item=>{
          item.value = "-";
          item.actionText = "-";
        })
    },

    mudarData() {
      (this.dtFim = this.formatDate(new Date().toISOString().substr(0, 10))),
        console.log(this.dtFim);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    mostrarFaturas() {
      this.showModal = true;
    },
    esconderBotaoFaturas() {
        this.showButtonFaturas = false;
    }
  },
};
</script>
<style>
.vue-treeselect__control {
  border-radius: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Estilo para o botão */
.botao-bonito {
  background-color: #007BFF; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  border: none; /* Sem borda */
  border-radius: 5px; /* Borda arredondada */
  padding: 10px 20px; /* Espaçamento interno (ajuste conforme necessário) */
  font-size: 16px; /* Tamanho da fonte */
  cursor: pointer; /* Cursor do mouse como ponteiro */
  transition: background-color 0.3s ease; /* Transição suave de cor de fundo */
  margin-bottom: 10px;
}

/* Estilo para o botão quando o mouse passar por cima */
.botao-bonito:hover {
  background-color: #0056b3; /* Cor de fundo alterada quando o mouse passar por cima */
}

</style>
