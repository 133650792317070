import {http} from "../plugins/http";

 let endpoint = '/'

export default {

    buscarTotalizadores:(params)=>{       
        let url = endpoint +'dashboard/filter?usuario='+localStorage.getItem('id')+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    buscarCTE:(params)=>{        
        let url = endpoint +'dashboard/filterCte?usuario='+localStorage.getItem('id')+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    buscarMDFE:(params)=>{
        let url = endpoint +'dashboard/filterMdfe?usuario='+localStorage.getItem('id')+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },

}