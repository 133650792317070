<template>
  <AppCard
    v-bind="$attrs"
    class="v-card--material mt-4"
  >
    <v-card-title class="align-start">
      <v-sheet
        :color="color"
        :width="fullHeader ? '100%' : undefined"
        id="status"
        elevation="6"
        max-width="100%"
        rounded
      >
        <v-theme-provider
          v-if="hasHeading"
          dark
        >
          <div
            v-if="icon"
            :class="iconSmall ? 'pa-7' : 'pa-8'"
          >
            <v-icon
              :large="!iconSmall"
              v-text="icon"
            />
          </div>

          <slot name="heading" />

          <div
            v-if="heading"
            class="text-h4 white--text pa-7 v-card--material__title"
          >
            {{ heading }}
          </div>
        </v-theme-provider>
      </v-sheet>

      <div
        v-if="hasTitle"
        :class="fullHeader ? 'pt-4' : 'pl-3'"
        class="text-h4 v-card--material__title"
        style=" font-size:1rem !important"
      >
        <slot name="title" />

        <template v-if="title">
          {{ title }}
        </template>

        <div class="text-subtitle-1 mb-n4">
          <slot name="subtitle" />

          <template v-if="subtitle">
            {{ subtitle }}
          </template>
        </div>
      </div>
    </v-card-title>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </AppCard>
</template>

<script>
 import AppCard from "../app/Card";
  export default {
    name: 'MaterialCard',
    components:{AppCard},

    props: {
      color: String,
      fullHeader: Boolean,
      heading: String,
      icon: String,
      iconSmall: Boolean,
      subtitle: String,
      title: String,
    },

    computed: {
      hasHeading () {
        return !!(
          this.icon ||
          this.heading ||
          this.$slots.heading
        )
      },
      hasTitle () {
        return !!(
          this.title ||
          this.subtitle ||
          this.$slots.title ||
          this.$slots.subtitle
        )
      },
    },
  }
</script>

<style lang="scss">
  #status{
    max-width: 100%;
    padding: 16px!important;
    border-radius: 4px!important;
    margin-top: -2px!important;
    overflow: hidden!important;
    transition: .3s cubic-bezier(.25,.8,.5,1)!important;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12)!important;
    background-repeat: no-repeat;
    box-sizing: inherit;
    color: white;
    font-size: calc(1vw + 0.5vh);
    font-weight: 500;
    letter-spacing: .0125em;
    line-height: 2rem;
    word-break: break-all;
}
.text-truncate{
  margin-left:10px
}



</style>
