<template>
  <div class="modal-overlay">
    <div class="modal">
      <h6>{{ textoTituloFatura }}</h6>
      <p>{{ textoDescricao }}</p>
      <table style="width: 100%">
        <tr>
          <th>CNPJ</th>
          <th>Transportador</th>
          <th>Baixar</th>
          <th>PIX</th>
          <th>Referência</th>
          <th>Qtd CTEs</th>
          <th>Valor</th>
        </tr>
        <tr v-for="fatura in faturas" :key="fatura">
          <td>{{ fatura.transportador.cnpj }}</td>
          <td>{{ fatura.transportador.nomeFantasia }}</td>
          <td><a @click.prevent="downloadPDF(fatura.linkFatura)" href="#">PDF</a></td>
          <td><a @click.prevent="copiarPix(fatura.codigoPix)" href="#">PIX</a></td>
          <td>{{ fatura.mes }} / {{ fatura.ano }}</td>
          <td>{{ fatura.qtde }}</td>
          <td style="text-align: right; padding-right: 5px">
            {{ fatura.valorTotal.toFixed(2).replace(".", ",") }}
          </td>
        </tr>
        <tr>
          <td class="whats">
            <a :href="linkWhatsApp"
              >Whatsapp {{ foneCobranca.ddd }}{{ foneCobranca.numero }}</a
            >
          </td>
        </tr>
      </table>
      <button @click="$emit('close-modal')">OK</button>
    </div>
  </div>
</template>

<script>
import Movimentacao from "../service/movimentacao";
import { mapActions } from "vuex";

export default {
  props: {
    fraseFatPend: String,
  },
  data() {
    return {
      texto: this.fraseFatPend,
      faturas: {},
      foneCobranca: "",
      linkWhatsApp: "",
      textoTituloFatura: "Faturas Pendentes",
      textoDescricao:
        "Ainda não identificamos o pagamento para as faturas abaixo. Caso o pagamento tenha sido efetuado, envie-nos o comprovante no whatsapp abaixo",
    };
  },
  mounted() {
    if (localStorage.getItem("permissoes") === "ROLE_USUARIO_OPERADOR" || localStorage.getItem("permissoes") === "ROLE_USUARIO_MONITOR") {
      this.$emit("semFaturas");
      return;
    }

    Movimentacao.getAllFaturas().then((response) => {
      var faturasPendentes = response.data;
      if (
        faturasPendentes.transportadores != undefined &&
        faturasPendentes.transportadores.length > 0
      ) {
        this.mostrarFaturasPendentes(faturasPendentes);
      } else {
        this.$emit("semFaturas");
      }
    });
  },
  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    mostrarFaturasPendentes(faturasPendentes) {
      var faturasPendentesAjustadas = [];

      var temVencido = false;

      for (var i = 0; i < faturasPendentes.transportadores.length; i++) {
        var transportador = faturasPendentes.transportadores[i];

        for (var j = 0; j < transportador.debitos.length; j++) {
          var debito = transportador.debitos[j];
          debito.transportador = transportador;

          var linkFatura =
            "https://cte-engine-api-drygfuxawq-ue.a.run.app/v1/cte/faturas/" +
            debito.transportador.cnpj +
            "/referencia/" +
            debito.ano +
            "/" +
            debito.mes;
          debito.linkFatura = linkFatura;

          faturasPendentesAjustadas.push(debito);

          this.foneCobranca = faturasPendentes.foneCobranca;
          this.linkWhatsApp =
            "https://api.whatsapp.com/send?phone=" +
            this.foneCobranca.ddd +
            this.foneCobranca.numero;

          if (debito.status === "VENCIDO") temVencido = true;
        }
      }

      if (faturasPendentesAjustadas.length == 1) {
        this.texto = "Há uma fatura pendente de pagamento";
        this.faturas = faturasPendentesAjustadas;

        if (temVencido) {
          this.$emit("show-modal");
        } else {
          this.textoTituloFatura = "Fatura em aberto";
          this.textoDescricao = "Há uma fatura em aberto, mas ainda dentro do prazo";
        }
      } else {
        this.texto =
          "Há " + faturasPendentesAjustadas.length + " faturas pendentes de pagamento";
        this.faturas = faturasPendentesAjustadas;

        if (temVencido) {
          this.$emit("show-modal");
        } else {
          this.textoTituloFatura = "Faturas em aberto";
          this.textoDescricao = "Há faturas em aberto, mas ainda dentro do prazo";
        }
      }
    },
    async downloadPDF(url) {
      const pdfUrl = url; // Substitua pelo URL do PDF desejado
      const authToken = localStorage.getItem("token"); // Substitua pelo seu token de autorização

      try {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${authToken}`);

        const response = await fetch(pdfUrl, {
          headers: headers,
        });

        const blob = await response.blob();

        // Cria um URL temporário para o blob recebido.
        const url = window.URL.createObjectURL(blob);

        // Cria um link <a> temporário para o URL e clique nele para iniciar o download.
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "fatura.pdf"); // Use o ID do botão no nome do arquivo.

        // Clique automaticamente no link para iniciar o download.
        link.style.display = "none"; // Oculta o link.
        document.body.appendChild(link);
        link.click();

        // Limpe o URL temporário e o link.
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Erro ao baixar o PDF:", error);
      }
    },
    copiarPix(conteudo) {
      // Cria um elemento de input de texto oculto
      var input = document.createElement("input");

      // Define o valor do campo de input com o conteúdo fornecido
      input.setAttribute("value", conteudo);

      // Adiciona o elemento input ao documento
      document.body.appendChild(input);

      // Seleciona o conteúdo do campo de input
      input.select();

      // Tenta copiar o conteúdo para a área de transferência
      var copiado = document.execCommand("copy");

      // Remove o elemento input do documento
      document.body.removeChild(input);

      if (copiado) {
        this.snackSuccess("Código PIX copiado");
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 500px;
  width: 800px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
  max-height: 80vh; /* Define uma altura máxima de 80% da altura da janela */
  min-height: 200px; /* Define uma altura mínima */
  overflow-y: auto; /* Ativa a rolagem vertical quando necessário */
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
  padding: 10px;
}

button {
  background-color: #0000aa;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

table,
th,
td {
  border: 1px solid black;
}
th {
  background-color: #0000aa;
  color: #ffffff;
}
</style>
